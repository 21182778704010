<template>
  <div class="h-full">
    <div class="bottom_space rounded py-2 h-full bg-white lg:w-1/2 w-full">
      <div v-if="!isLoading">
        <div style="overflow-y: autos; white-space: nowrap;" class="p-3 cust_card">
          <div class="flex items-center justify-between">
            <div class=" text-text1" :class="mobileView ? 'heading-5' : 'heading-3'">● Settings:</div>
            <div v-if="!opensettingEdit" class=" text-primary cursor-pointer heading-4" @click="opensettingEdit = !opensettingEdit"><i class="fas fa-pen-to-square"></i></div>
            <span v-if="opensettingEdit" class="flex items-center">
              <div class=" text-white heading-5 cursor-pointer bg-error px-2.5 py-1 rounded-full" @click.stop="closeUserInfoEdit(false)">
                <i class="fas fa-times"></i>
              </div>
              <div class=" text-white heading-5 cursor-pointer bg-secondary px-2.5 py-1 rounded-full ml-2" @click.stop="closeUserInfoEdit(true)">
                <i class="fas fa-check"></i>
              </div>
            </span>
          </div>
          <div v-if="opensettingEdit">
            <div class="mt-4">
              <div class="text-gray4 heading-5 "><p class="mb-0 whitespace-pre-line" >1) Send Max <input type="number" v-model.number="timeObj.perMin" class="page-number-input border border-gray4 text-center"/> messages per minute for all campaigns.</p></div>
            </div>
            <!-- <div class="mt-4">
              <div class="text-gray4 heading-5 "><p class="mb-0" style="width:173px">2) Send Max <input type="number" v-model.number="timeObj.perHour" class="page-number-input border border-gray4 text-center"/> messages per hour.</p></div>
            </div> -->
            <div class="mt-4">
              <div class="text-gray4 heading-5 "><p class="mb-0 whitespace-pre-line">2) Send Max <input type="number" v-model.number="timeObj.perDay" class="page-number-input border border-gray4 text-center"/> messages per day for all campaigns.</p></div>
            </div>
          </div>
          <div v-if="!opensettingEdit">
            <div class="mt-4">
              <div class="text-gray4 heading-5 "><p class="mb-0 whitespace-pre-line" >1) Send Max <span class="font-semibold">{{msgPerMin}}</span> messages per minute for all campaigns.</p></div>
            </div>
            <!-- <div class="mt-4">
              <div class="text-gray4 heading-5 "><p class="mb-0" style="width:173px">2) Send Max <span class="font-semibold">{{msgPerHour}}</span> messages per hour.</p></div>
            </div> -->
            <div class="mt-4">
              <div class="text-gray4 heading-5 "><p class="mb-0 whitespace-pre-line">2) Send Max <span class="font-semibold">{{msgPerDay}}</span> messages per day for all campaigns.</p></div>
            </div>
          </div>
        </div>
        <div style="overflow-y: autos; white-space: nowrap;" class="p-3 cust_card mt-3">
          <div class="flex items-center justify-between cursor-pointer" @click="isSHowTwil = !isSHowTwil">
            <div class=" text-text1" :class="mobileView ? 'heading-5' : 'heading-3'">● Twilio Configuration:</div>
            <div class=" text-primary cursor-pointer heading-4">
              <span v-if="!isSHowTwil"><i class="fas fa-chevron-down"></i></span>
              <p v-if="isSHowTwil"><i class="fas fa-chevron-up"></i></p>
            </div>
          </div>
          <div class="cust_card p-2 mt-2 bg-gray-50 border border-gray-200" v-if="isSHowTwil">
            <div class="flex justify-end py-2">
              <Button @buttonAction="addNewStaff()" class="ml-2" :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Add Number'"/>
            </div>
            <div class="p-2 pb-0" v-if="twilioConfigListArray !== null">
              <div v-for="(item, i) in twilioConfigListArray" :key="i + 'i'" class="relative"  @click="selectNumber(item)">
                <div class="card rounded-xl bg-white mb-2 p-3 cursor-pointer hover:bg-gray-200 hover:border-gray-300 border border-transparent" >
                  <div class="" :class="`${item.isActive ? 'text-text1 ' : 'text-text1 '} ${mobileView ? 'heading-6' : 'flex justify-between '}`">
                    <p class="">{{item.configLabel}} <span v-if="!item.isActive" class="text-error heading-6">(Inactive)</span></p>
                    <p>{{item.phoneNumber | formatPhoneNumber }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="layout my-2" v-else>
              <div class="flex p-3 text-sm text-primary bg-blue-100 rounded-lg  items-center" role="alert">
                <svg aria-hidden="true" class="flex-shrink-0 inline w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                <span class="sr-only">Info</span>
                <div>
                  <p class=" font-medium heading-4">No record found.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="overflow-y: autos; white-space: nowrap;" class="p-3 cust_card mt-3">
          <div class="flex items-center justify-between cursor-pointer" @click="iShowPlivo = !iShowPlivo">
            <div class=" text-text1" :class="mobileView ? 'heading-5' : 'heading-3'">● Plivo Configuration:</div>
            <div class=" text-primary cursor-pointer heading-4">
              <span v-if="!iShowPlivo" ><i class="fas fa-chevron-down"></i></span>
              <p v-if="iShowPlivo"><i class="fas fa-chevron-up"></i></p>
            </div>
          </div>
          <div class="cust_card p-2 mt-2 bg-gray-50 border border-gray-200" v-if="iShowPlivo">
            <div class="flex justify-end py-2">
              <Button @buttonAction="addNewNumber()" class="ml-2" :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Add Number'"/>
            </div>
            <div class="p-2 pb-0" v-if="plivoConfigListArray !== null">
              <div v-for="(item, i) in plivoConfigListArray" :key="i + 'i'" class="relative"  @click="getPlivoConfigurationNoDetail(item)">
                <div class="card rounded-xl bg-white mb-2 p-3 cursor-pointer hover:bg-gray-200 hover:border-gray-300 border border-transparent" >
                  <div class="" :class="`${item.isActive ? 'text-text1 ' : 'text-text1 '} ${mobileView ? 'heading-6' : 'flex justify-between '}`">
                    <p class="">{{item.configLabel}} <span v-if="!item.isActive" class="text-error heading-6">(Inactive)</span></p>
                    <p>{{item.phoneNumber | formatPhoneNumber }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="layout my-2" v-else>
              <div class="flex p-3 text-sm text-primary bg-blue-100 rounded-lg  items-center" role="alert">
                <svg aria-hidden="true" class="flex-shrink-0 inline w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                <span class="sr-only">Info</span>
                <div>
                  <p class=" font-medium heading-4">No record found.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="detailPopup" class="popup_overlay relative px-4">
    <div style="width: 800px;" class="custom_dialog rounded-xl h-min">
      <div  class=" bg-primary flex justify-between p-3 sticky top-0">
        <p class="heading-4 text-white">Detail</p>
        <div class="flex items-center gap-3">
          <div class="heading-5 text-primary cursor-pointer bg-white px-3 py-1 rounded-xl" @click.stop="editScreen()">
            <i class="fas fa-pen-to-square"></i>
          </div>
        </div>
      </div>
      <div class="p-4">
        <div class="">
          <div class="p-1">
            <p class="mb-0" style="display: flex;">
              <span class="text-text2 heading-5 font-semibold fixWidth"><p class="mb-0">Config Label</p></span>
              <span><p class="mb-0 dotwidth">:</p></span>
              <span class="text-text1 heading-5 whitespace-pre-line overFlowParaA">{{selectedObject.configLabel}}</span>
            </p>
            <p class="mb-0" style="display: flex;">
              <span class="text-text2 heading-5 font-semibold fixWidth"><p class="mb-0">Phone #</p></span>
              <span><p class="mb-0 dotwidth">:</p></span>
              <span class="text-text1 heading-5 whitespace-pre-line overFlowParaA" >{{selectedObject.phoneNumber | phone}}</span>
            </p>
            <p class="mb-0" style="display: flex;">
              <span class="text-text2 heading-5 font-semibold fixWidth"><p class="mb-0">Account SID</p></span>
              <span><p class="mb-0 dotwidth">:</p></span>
              <span class="text-text1 heading-5 whitespace-pre-line overFlowParaA" >{{selectedObject.accountSid}}</span>
            </p>
            <p class="mb-0" style="display: flex;">
              <span class="text-text2 heading-5 font-semibold fixWidth"><p class="mb-0">App SID</p></span>
              <span><p class="mb-0 dotwidth">:</p></span>
              <span class="text-text1 heading-5 whitespace-pre-line overFlowParaA" >{{selectedObject.appSid}}</span>
            </p>
            <p class="mb-0" style="display: flex;">
              <span class="text-text2 heading-5 font-semibold fixWidth"><p class="mb-0">Api Key</p></span>
              <span><p class="mb-0 dotwidth">:</p></span>
              <span class="text-text1 heading-5 whitespace-pre-line overFlowParaA" >{{selectedObject.apiKey}}</span>
            </p>
            <p class="mb-0" style="display: flex;">
              <span class="text-text2 heading-5 font-semibold fixWidth"><p class="mb-0">Api Secret</p></span>
              <span><p class="mb-0 dotwidth">:</p></span>
              <span class="text-text1 heading-5 whitespace-pre-line overFlowParaA" >{{selectedObject.apiSecret}}</span>
            </p>
            <p class="mb-0" style="display: flex;">
              <span class="text-text2 heading-5 font-semibold fixWidth"><p class="mb-0">Messaging Service SID</p></span>
              <span><p class="mb-0 dotwidth">:</p></span>
              <span class="text-text1 heading-5 whitespace-pre-line overFlowParaA" >{{selectedObject.messagingServiceSid}}</span>
            </p>
            <p v-if="selectedObject.isActive" class="py-2 heading-5 font-semibold text-text2">This Number is Active</p>
            <p v-if="!selectedObject.isActive" class="py-2 heading-5 text-error">This Number is Inactive</p>
          </div>
      </div>
    </div>
    </div>
  </div>
    <div v-if="twilioConfPopup" class="popup_overlay relative px-4">
    <div style="width: 800px;max-height: 87vh;overflow: auto" class="custom_dialog rounded-xl h-min">
      <div  class=" bg-primary flex justify-between p-3 sticky top-0 z-10">
        <p class="heading-4 text-white">{{addNumberObject.twilioConfigurationId > 0 ? 'Edit Twilio Configuration' : 'Add Twilio Configuration'}}</p>
      </div>
      <div class="p-4 grid lg:grid-cols-2 gap-2">
        <div>
          <TextField
            :inputId="'TwilName'"
            :showcharLimit="false"
            :inputext="addNumberObject.configLabel"
            :placholderText="`Config Label`"
            :lableText="'Config Label'"
            :autoFocus="false"
            :fieldError="nameErr !== ''"
            @keyPressAction="nameErr = ''"
            @inputChangeAction="(data) => addNumberObject.configLabel = data"  />
            <p class="text-error heading-7">{{nameErr}}</p>
        </div>
        <div>
          <NumberInput
            :inputId="'TwilNumber'"
            :showcharLimit="false"
            :textMaxlength="12"
            :inputext="addNumberObject.phoneNumber"
            :placholderText="`Phone Number`"
            :lableText="'Phone Number'"
            :autoFocus="false"
            @keyPressAction="numErr = ''"
            @blurAction="checkContactNumber()"
            :fieldError="numErr !== ''"
            @inputChangeAction="(data) => addNumberObject.phoneNumber = data"  />
            <p class="text-error heading-7">{{numErr}}</p>
        </div>
        <div>
          <TextField
            :inputId="'TwilSid'"
            :showcharLimit="false"
            :inputext="addNumberObject.accountSid"
            :placholderText="`Account SID`"
            :lableText="'Account SID'"
            :autoFocus="false"
            @keyPressAction="accSidErr = ''"
            :fieldError="accSidErr !== ''"
            @inputChangeAction="(data) => addNumberObject.accountSid = data"  />
            <p class="text-error heading-7">{{accSidErr}}</p>
        </div>
        <div>
          <TextField
            :inputId="'TwilAuth'"
            :showcharLimit="false"
            :inputext="addNumberObject.appSid"
            :placholderText="`Account AuthToken`"
            :lableText="'Account AuthToken'"
            :autoFocus="false"
            @keyPressAction="appSIDErr = ''"
            :fieldError="appSIDErr !== ''"
            @inputChangeAction="(data) => addNumberObject.appSid = data"  />
            <p class="text-error heading-7">{{appSIDErr}}</p>
        </div>
        <div>
          <TextField
            :inputId="'TwilAKey'"
            :showcharLimit="false"
            :inputext="addNumberObject.apiKey"
            :placholderText="`Api Key`"
            :lableText="'Api Key'"
            :autoFocus="false"
            @keyPressAction="apiKeyErr = ''"
            :fieldError="apiKeyErr !== ''"
            @inputChangeAction="(data) => addNumberObject.apiKey = data"  />
            <p class="text-error heading-7">{{apiKeyErr}}</p>
        </div>
        <div>
          <TextField
            :inputId="'TwilASec'"
            :showcharLimit="false"
            :inputext="addNumberObject.apiSecret"
            :placholderText="`Api Secret`"
            :lableText="'Api Secret'"
            :autoFocus="false"
            @keyPressAction="apiSecretErr = ''"
            :fieldError="apiSecretErr !== ''"
            @inputChangeAction="(data) => addNumberObject.apiSecret = data"  />
            <p class="text-error heading-7">{{apiSecretErr}}</p>
        </div>
        <div>
          <TextField
            :inputId="'mesSerSid'"
            :showcharLimit="false"
            :inputext="addNumberObject.messagingServiceSid"
            :placholderText="`Messaging Service SID`"
            :lableText="'Messaging Service SID'"
            :autoFocus="false"
            @keyPressAction="msgSerErr = ''"
            :fieldError="msgSerErr !== ''"
            @inputChangeAction="(data) => addNumberObject.messagingServiceSid = data"  />
            <p class="text-error heading-7">{{msgSerErr}}</p>
        </div>
      </div>
        <div class="flex items-center px-4">
          <label class="cu_switch cursor-pointer mr-3" :class="!addNumberObject.isActive ? 'ml-2' : ''">
            <input type="checkbox" v-model="addNumberObject.isActive">
            <span class="cu_slider cu_round"></span>
          </label>
          <p class="text-gray3 pl-1">{{addNumberObject.isActive ? 'Number is Active' : 'Number is Inactive'}}</p>
        </div>
      <div class="flex gap-2 justify-end p-3">
        <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="clickOnBtn('twilio')"/>
        <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Save'" @buttonAction="saveAPI"/>
      </div>
    </div>
  </div>
  <div v-if="plivoConfPopup" class="popup_overlay relative px-4">
    <div style="width: 850px;max-height: 87vh;overflow: auto" class="custom_dialog rounded-xl h-min">
      <div  class=" bg-primary flex justify-between p-3 sticky top-0 z-10">
        <p class="heading-4 text-white">{{addPlivoObject.plivoConfigurationId > 0 ? 'Edit Plivo Configuration' : 'Add Plivo Configuration'}}</p>
      </div>
      <div class="p-4 grid lg:grid-cols-2 gap-2">
        <div>
          <TextField
            :inputId="'TwilName'"
            :showcharLimit="false"
            :inputext="addPlivoObject.configLabel"
            :placholderText="`Config Label`"
            :lableText="'Config Label'"
            :autoFocus="false"
            :fieldError="pNameErr !== ''"
            @keyPressAction="pNameErr = ''"
            @inputChangeAction="(data) => addPlivoObject.configLabel = data"  />
            <p class="text-error heading-7">{{pNameErr}}</p>
        </div>
        <div>
          <NumberInput
            :inputId="'TwilNumber'"
            :showcharLimit="false"
            :textMaxlength="12"
            :inputext="addPlivoObject.phoneNumber"
            :placholderText="`Phone Number`"
            :lableText="'Phone Number'"
            :autoFocus="false"
            @keyPressAction="pNumErr = ''"
            @blurAction="checkContactNumber()"
            :fieldError="pNumErr !== ''"
            @inputChangeAction="(data) => addPlivoObject.phoneNumber = data"  />
            <p class="text-error heading-7">{{pNumErr}}</p>
        </div>
        <div>
          <TextField
            :inputId="'TwilSid'"
            :showcharLimit="false"
            :inputext="addPlivoObject.authId"
            :placholderText="`Auth ID`"
            :lableText="'Auth ID'"
            :autoFocus="false"
            @keyPressAction="pAccSidErr = ''"
            :fieldError="pAccSidErr !== ''"
            @inputChangeAction="(data) => addPlivoObject.authId = data"  />
            <p class="text-error heading-7">{{pAccSidErr}}</p>
        </div>
        <div>
          <TextField
            :inputId="'TwilAuth'"
            :showcharLimit="false"
            :inputext="addPlivoObject.authToken"
            :placholderText="`AuthToken`"
            :lableText="'AuthToken'"
            :autoFocus="false"
            @keyPressAction="pAppSIDErr = ''"
            :fieldError="pAppSIDErr !== ''"
            @inputChangeAction="(data) => addPlivoObject.authToken = data"  />
            <p class="text-error heading-7">{{pAppSIDErr}}</p>
        </div>
      </div>
        <div class="flex items-center px-4">
          <label class="cu_switch cursor-pointer mr-3" :class="!addPlivoObject.isActive ? 'ml-2' : ''">
            <input type="checkbox" v-model="addPlivoObject.isActive">
            <span class="cu_slider cu_round"></span>
          </label>
          <p class="text-gray3 pl-1">{{addPlivoObject.isActive ? 'Number is Active' : 'Number is Inactive'}}</p>
        </div>
      <div class="flex gap-2 justify-end p-3">
        <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="clickOnBtn('plivo')"/>
        <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Save'" @buttonAction="PlivoSaveAPI"/>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import NumberInput from '@/View/components/number-text-field.vue'
import ADMINAPI from '@/View/Admin/api/Admin.js'
import TextField from '@/View/components/textfield.vue'
import Button from '@/View/components/globalButton.vue'
// import Pagination from '@/View/components/pagination.vue'
import deboucneMixin from '@/mixins/debounce.js'
export default {
  components: {
    Button,
    NumberInput,
    TextField
  },
  mixins: [deboucneMixin],
  data () {
    return {
      iShowPlivo: false,
      isSHowTwil: false,
      timeObj: {
        messageSendLimitId: 0,
        perMin: 0,
        perHour: 0,
        perDay: 0,
      },
      selectedObject: null,
      nameErr: '',
      numErr: '',
      accSidErr: '',
      appSIDErr: '',
      apiKeyErr: '',
      apiSecretErr: '',
      msgSerErr: '',
      pNameErr: '',
      pNumErr: '',
      pAccSidErr: '',
      pAppSIDErr: '',
      addPlivoObject: {
        plivoConfigurationId: 0,
        configLabel: '',
        phoneNumber: '',
        authId: '',
        authToken: '',
        isActive: true,
      },
      addNumberObject: {
        twilioConfigurationId: 0,
        configLabel: '',
        phoneNumber: '',
        accountSid: '',
        appSid: '',
        apiKey: '',
        apiSecret: '',
        messagingServiceSid: '',
        twimalAppId: '',
        isActive: true,
      },
      detailPopup: false,
      twilioConfPopup: false,
      plivoConfPopup: false,
      isAssign: false,
      twilioConfigListArray: null,
      selectedVal: '',
      opensettingEdit: false,
      msgPerHour: 0,
      msgPerMin: 0,
      msgPerDay: 0,
      isLoading: false,
      mobileView: false,
      plivoConfigListArray: null
    }
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted () {
    window.scrollTo({top: 0})
    this.resizeWindowHandler();
    this.getTwilioListing()
    this.getPlivoListing()
    this.getMessageData()
    document.title = 'Global Setting'
  },
  methods: {
    checkContactNumber () {
      if (this.addNumberObject.phoneNumber.length < 10) {
        this.numErr = 'Please enter valid 10 digit contact number'
      } else {
        this.numErr = ''
      }
    },
    addNewStaff () {
      this.twilioId = 0
      this.twilioConfPopup = true
    },
    addNewNumber () {
      this.plivoConfPopup = true
    },
    openEditPopup () {
      this.twilioId = 0
      this.twilioConfPopup = true
    },
    editScreen () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Please wait...'})
      ADMINAPI.GetTwilioConfDetail(
        this.selectedObject.twilioConfigurationId,
        response => {
          this.addNumberObject.twilioConfigurationId = response.Data.twilioConfigurationId
          this.addNumberObject.configLabel = response.Data.configLabel
          this.addNumberObject.phoneNumber = response.Data.phoneNumber
          this.addNumberObject.accountSid = response.Data.accountSid
          this.addNumberObject.appSid = response.Data.appSid
          this.addNumberObject.apiKey = response.Data.apiKey
          this.addNumberObject.apiSecret = response.Data.apiSecret
          this.addNumberObject.messagingServiceSid = response.Data.messagingServiceSid
          this.addNumberObject.twimalAppId = response.Data.twimalAppId
          this.addNumberObject.isActive = response.Data.isActive
          this.detailPopup = false
          this.twilioConfPopup = true
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    saveAPI () {
      let isvalid = true
      if (this.addNumberObject.configLabel === '') {
        this.nameErr = 'Name is required'
        isvalid = false
      }
      if (this.addNumberObject.phoneNumber === '') {
        this.numErr = 'Phone Number is required'
        isvalid = false
      }
      if (this.numErr !== '') {
        isvalid = false
      }
      if (this.addNumberObject.accountSid === '') {
        this.accSidErr = 'Account SID is required'
        isvalid = false
      }
      if (this.addNumberObject.appSid === '') {
        this.appSIDErr = 'App SID is required'
        isvalid = false
      }
      if (this.addNumberObject.apiKey === '') {
        this.apiKeyErr = 'Api Key is required'
        isvalid = false
      }
      if (this.addNumberObject.apiSecret === '') {
        this.apiSecretErr = 'Api Secret is required'
        isvalid = false
      }
      if (this.addNumberObject.messagingServiceSid === '') {
        this.msgSerErr = 'Messaging Service SID is required'
        isvalid = false
      }
      if (isvalid) {
        this.$store.dispatch('SetLoader', {status: true, message: 'Please wait...'})
        ADMINAPI.TwilioConfiguration(
          this.addNumberObject,
          response => {
            this.twilioConfPopup = false
            this.getTwilioListing()
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          (error) => {
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
      }
    },
    PlivoSaveAPI () {
      let isvalid = true
      if (this.addPlivoObject.configLabel === '') {
        this.pNameErr = 'Name is required'
        isvalid = false
        console.log('ee-1');
      }
      if (this.addPlivoObject.phoneNumber === '') {
        this.pNumErr = 'Phone Number is required'
        console.log('ee-2');
        isvalid = false
      }
      if (this.pNumErr !== '') {
        isvalid = false
        console.log('ee-6');
      }
      if (this.addPlivoObject.authId === '') {
        console.log('ee-3');
        this.pAccSidErr = 'Auth Id is required'
        isvalid = false
      }
      if (this.addPlivoObject.authToken === '') {
        console.log('ee-4');
        this.pAppSIDErr = 'Account AuthToken is required'
        isvalid = false
      }
      console.log('ee-5', isvalid);
      console.log('addPlivoObject', this.addPlivoObject);
      if (isvalid) {
        this.$store.dispatch('SetLoader', {status: true, message: 'Please wait...'})
        ADMINAPI.PlivoConfigurationSave(
          this.addPlivoObject,
          response => {
            this.plivoConfPopup = false
            this.getPlivoListing()
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          (error) => {
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
      }
    },
    closeUserInfoEdit (data) {
      if (data) {
        if (this.timeObj.perMin === '') this.timeObj.perMin = 0
        if (this.timeObj.perHour === '') this.timeObj.perHour = 0
        if (this.timeObj.perDay === '') this.timeObj.perDay = 0
        this.$store.dispatch('SetLoader', {status: true, message: 'Please wait...'})
        ADMINAPI.MsgLimitUpdate(
          this.timeObj,
          response => {
            this.getMessageData()
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          },
          (error) => {
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
      }
      this.opensettingEdit = false
    },
    getMessageData () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Please wait...'})
      ADMINAPI.GetMessageLimit(
        response => {
          this.timeObj.messageSendLimitId = response.Data.messageSendLimitId
          this.timeObj.perMin = response.Data.perMin
          this.timeObj.perHour = response.Data.perHour
          this.timeObj.perDay = response.Data.perDay
          this.msgPerHour = response.Data.perHour
          this.msgPerMin = response.Data.perMin
          this.msgPerDay = response.Data.perDay
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    getTwilioListing () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Please wait...'})
      ADMINAPI.TwilioConfigurationList(
        0,
        50,
        '',
        '',
        '',
        '',
        response => {
          let tempData = response.Data.tableRow !== null ? response.Data.tableRow  : null
          if (response.Data.tableRow !== null) {
            this.twilioConfigListArray = tempData.slice().sort(this.sortByIsActive);
          } else {
            this.twilioConfigListArray = tempData
          }
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    getPlivoListing () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Please wait...'})
      ADMINAPI.PlivoConfigurationList(
        0,
        50,
        '',
        '',
        '',
        '',
        response => {
          let tempData = response.Data.tableRow !== null ? response.Data.tableRow  : null
          if (response.Data.tableRow !== null) {
            this.plivoConfigListArray = tempData.slice().sort(this.sortByIsActive);
          } else {
            this.plivoConfigListArray = tempData
          }
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    sortByIsActive(obj1, obj2) {
      // Sort by isActive in descending order (true first, false later)
      return obj2.isActive - obj1.isActive;
    },
    clickOnBtn (from) {
      if (from === 'twilio') {
        this.addNumberObject.twilioConfigurationId = 0
        this.addNumberObject.configLabel = ''
        this.addNumberObject.phoneNumber = ''
        this.addNumberObject.accountSid = ''
        this.addNumberObject.appSid = ''
        this.addNumberObject.apiKey = ''
        this.addNumberObject.apiSecret = ''
        this.addNumberObject.messagingServiceSid = ''
        this.nameErr = ''
        this.numErr = ''
        this.accSidErr = ''
        this.appSIDErr = ''
        this.apiKeyErr = ''
        this.apiSecretErr = ''
        this.msgSerErr = ''
        this.twilioConfPopup = false
      } else {
        this.addPlivoObject.plivoConfigurationId = 0
        this.addPlivoObject.configLabel = ''
        this.addPlivoObject.phoneNumber = ''
        this.addPlivoObject.authToken = ''
        this.addPlivoObject.authId = ''
        this.pNameErr = ''
        this.pNumErr = ''
        this.pAccSidErr = ''
        this.pAppSIDErr = ''
        this.plivoConfPopup = false
      }
    },
    selectNumber (data) {
      this.$store.dispatch('SetLoader', {status: true, message: 'Please wait...'})
      ADMINAPI.GetTwilioConfDetail(
        data.twilioConfigurationId,
        response => {
          this.addNumberObject.twilioConfigurationId = response.Data.twilioConfigurationId
          this.addNumberObject.configLabel = response.Data.configLabel
          this.addNumberObject.phoneNumber = response.Data.phoneNumber
          this.addNumberObject.accountSid = response.Data.accountSid
          this.addNumberObject.appSid = response.Data.appSid
          this.addNumberObject.apiKey = response.Data.apiKey
          this.addNumberObject.apiSecret = response.Data.apiSecret
          this.addNumberObject.messagingServiceSid = response.Data.messagingServiceSid
          this.addNumberObject.twimalAppId = response.Data.twimalAppId
          this.addNumberObject.isActive = response.Data.isActive
          this.detailPopup = false
          this.twilioConfPopup = true
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
      // this.selectedObject = data
      // this.$store.dispatch('SetLoader', {status: true, message: 'Please wait...'})
      // ADMINAPI.GetTwilioConfDetail(
      //   data.twilioConfigurationId,
      //   response => {
      //     this.selectedObject = response.Data
      //     this.detailPopup = true
      //     this.$store.dispatch('SetLoader', {status: false, message: ''})
      //   },
      //   (error) => {
      //     this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
      //     this.$store.dispatch('SetLoader', {status: false, message: ''})
      //   }
      // )
    },
    getPlivoConfigurationNoDetail (data) {
      this.$store.dispatch('SetLoader', {status: true, message: 'Please wait...'})
      ADMINAPI.PlivoConfigurationDetail(
        data.plivoConfigurationId,
        response => {
          this.addPlivoObject.plivoConfigurationId = response.Data.plivoConfigurationId
          this.addPlivoObject.configLabel = response.Data.configLabel
          this.addPlivoObject.phoneNumber = response.Data.phoneNumber
          this.addPlivoObject.authToken = response.Data.authToken
          this.addPlivoObject.authId = response.Data.authId
          this.addPlivoObject.isActive = response.Data.isActive
          this.detailPopup = false
          this.plivoConfPopup = true
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    smoothScroll (target) {
      window.scrollTo({
        top: document.getElementById(target).offsetTop - 60,
        left: 0,
        behavior: "smooth",
      });
    },
    resizeWindowHandler() {
      if (window.innerWidth < 684) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
    },
  }
}
</script>
<style scoped>

.fixWidth {
  width: 115px;
  min-width: 115px;
}
.dotwidth {
  width: 15px;
}
.list_top_card_btn {
  width: 83px !important;
  margin-right: 15px !important;
}
.ImageTag {
  max-height: 300px;
  width: auto;
}
.page-number-input{
    width: 55px;
    display: inline-block;
    height: 30px;
    border-radius: 5px;
}
</style>import result from 'autoprefixer/data/prefixes'

