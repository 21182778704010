<template>
    <div class="relative">
           <p class="label_css flex justify-between">
            <span>{{lableText}}</span>
            <span class="text contents" v-if="showcharLimit">{{inputValue.length}}/{{textMaxlength}}</span>
            </p>
            <input
            :maxlength="textMaxlength"
            @keypress="isNumber($event)"
            @keypress.enter="enterkeyPressAction"  
            @blur="blurAction(inputValue)"
            @input="inputChangeAction(inputValue)"
            @change="change"
            @keyup="keyup"
            :pattern="pattern"
            @click="onClickInputAction"
            @focus="focus"
            v-model="inputValue"
            :disabled="disabled"
            :placeholder="placholderText"
            :readonly="setReadOnly"
            :type="inputTypeText"
            :class="`${inputType === 'color' ? 'h-12 py-0' : 'py-3'} ${showCountry ? 'pl-16':''} ${textRight ? 'text-right pr-2' : ''} ${showError ? 'border border-error text-error' : 'border border-gray2 flex text-text1 focus:border-primary focus:ring-1'}
            pr-${inputType === 'password' || showDate ? '8' : '0'}
            `"
            :autofocus="setAutoFocust"
            autocomplete="off"
            :id="inputId"
            class="cust_text_field  px-2 w-full bg-white rounded items-center">


                <span v-if="inputType === 'password'" @click.stop.prevent="hidePassword = !hidePassword" 
                  :class="lableText !== '' ? 'top-8' : 'top-3' "
                  class="place-self-center absolute  right-3 cursor-pointer mt-0.5">
                    <p v-if="!hidePassword">
                      <i class="fa-solid fa-eye-slash text-gray3 heading-6"></i>
                    </p>
                  <span v-if="hidePassword">
                    <i class="fa-solid fa-eye text-gray3 heading-6"></i>
                  </span>
                </span>
                <span  @click="onClickInputAction" v-if="showDate" 
                  :class="lableText !== '' ? 'top-8' : 'top-3' "
                  class="place-self-center absolute  right-3 cursor-pointer mt-0.5">
                  
                   <i class="fa-solid fa-calendar-days text-gray3 heading-6"></i>
                </span>
                <span @click="onClickInputAction" v-if="showTime" 
                :class="lableText !== '' ? 'top-8' : 'top-3' "
                class="place-self-center absolute  right-3 cursor-pointer mt-0.5">
                   <i class="fa-regular fa-clock heading-3 text-primary"></i>
                </span>
                <span @click="showCountrylist" v-click-outside-textInput="closePopup" class="absolute country_box left-1 border-r border-gray2 flex items-center justify-center text-text1 w-14" v-if="showCountry">
                  <input @blur="blurEventCountry" placeholder="Search" id="codesearch" v-show="showContrylist" style="outline: none;" class="w-12 text-text1 px-2" v-model="searchForCountry" type="text">
                  <span @click.stop.prevent="showCountrylist" v-if="!showContrylist">{{countryCode}}</span>
                </span>
                  
                  <div class=" z-10 flex-col w-full max-h-64 h-auto  bg-white overflow-auto border heading-4 text-gray-800 border-gray-200 shadow-lg" style="overflow-x: auto;position: absolute;" v-show="showContrylist" >
                    <div v-if="countryList.length > 0">
                        <div v-for="(data, index) in countryList" :key="index" @click="selectNewForVal(data, index)" class="flex shadow hover:bg-gray-100 items-center  cursor-pointer p-3 pl-2  border-b hover:bg-teal-100 border-transparent border-l-2 relative hover:border-teal-100" >
                          <div class=" heading-4">
                            {{ data.searchString }}
                          </div>
                        </div>
                    </div>
                    <div v-else>
                      <div class=" heading-4">
                          No Country
                        </div>
                    </div>
                  </div>
        </div>
</template>
<script>
// import countryPhoneCodeList from '@/assets/countryList.json'
import Vue from 'vue'
Vue.directive('click-outside-textInput', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
})
export default {
  components: {
  },
    props: [ 'showCountry', 'showcharLimit', 'pattern', "textRight", "inputext", "textMaxlength", 'lableText', 'inputId', 'autoFocus', 'fieldError', 'setReadOnly', 'placholderText', 'inputType', "showDate", "disabled", "showTime", "selectedCountry"],
  data() {
    return {
      countryPhoneCodeList: [],
      countryList: [],
      countryMainList: [],
      showContrylist: false,
      countryCode: '+1',
      inputTypeText: 'text',
      hidePassword: false,
      showError: false,
      setAutoFocust: false,
      disabledBtn: false,
      searchForCountry: '',
      inputValue: ''
    };
  },
  watch: {
    selectedCountry: {
        handler () {
          this.countryCode = this.selectedCountry
        }
    },
    fieldError: {
        handler () {
          this.showError = this.fieldError
        }
    },
    hidePassword: {
        handler () {
          if (this.hidePassword) {
            this.inputTypeText = 'password'
            document.getElementById(`${this.inputId}`).focus()
          } else {
            this.inputTypeText = 'text'
            document.getElementById(`${this.inputId}`).focus()
          }
        }
    },
    inputext: {
        handler () {
            if (this.inputext) {
                this.inputValue = this.inputext
            } else {
              this.inputValue = this.inputext
            }
        },
        deep: true
    },
    inputValue: {
        handler () {
            if (this.inputext !== this.inputValue) {
                this.inputValue = this.inputext
            }
        },
        deep: true
    },
    searchForCountry: {
     handler() {
        let filter = this.searchForCountry.toLowerCase();
        this.countryList = []
        for (let index = 0; index < this.countryMainList.length; index++) {
            if (this.searchForCountry.toLowerCase().indexOf(filter) > -1 === this.countryMainList[index].name.toLowerCase().indexOf(filter) > -1) {
                this.countryList.push(this.countryMainList[index])
            }
        }
    }
    },
  },
  mounted() {
    if (this.selectedCountry !== '') {
      this.countryCode = this.selectedCountry
    }
    this.countryList = this.countryPhoneCodeList
    this.countryMainList = this.countryPhoneCodeList
    this.showError = this.fieldError
    this.inputTypeText = this.inputType
    if (this.disabled) {
      this.disabledBtn = this.disabled
    }
    setTimeout(() => {
      this.inputValue = this.inputext
     }, 200);
    setTimeout(() => {
      if (this.autoFocus) {
        document.getElementById(`${this.inputId}`).focus()
      }
    }, 10);
  },
  methods: {
    showCountrylist () {
      this.showContrylist = true
      this.countryList = this.countryMainList
      setTimeout(() => {
        document.getElementById('codesearch').focus()
      }, 500);
    },
    selectNewForVal (data) {
      this.countryCode = data.dialCode
      this.$emit('selectCountry', this.countryCode)
        document.getElementById(`${this.inputId}`).focus()
    },
    isNumber (KeyboardEvent) {
      // if (this.inputType === 'number') {
        const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '+', '-', '.']
      const keyPressed = KeyboardEvent.key
      if (!keysAllowed.includes(keyPressed)) {
        KeyboardEvent.preventDefault()
      }
      // }
      this.$emit('keyPressAction')
    },
    inputChangeAction (text) {
        this.$emit('inputChangeAction', text)
    },
    change () {
        this.$emit('change')
    },
    // keyPressAction () {
    // },
    enterkeyPressAction () {
        this.$emit('enterkeyPressAction')
    },
    blurAction (value) {
        this.$emit('blurAction', value)
    },
    blurEventCountry () {
      setTimeout(() => {
        this.searchForCountry = ''
      }, 500);
    },
    focus () {
        this.$emit('focus')
    },
    onClickInputAction (event) {
      document.getElementById(`${this.inputId}`).focus()
        this.$emit('onClickInputAction')
        event.target.setSelectionRange(0, String(this.inputValue).length);
    },
    closePopup () {
      this.showContrylist = false
      this.$emit('closePopup')
    },
    keyup () {
        this.$emit('keyup')
    }
  },
};
</script>
<style scoped>
.country_box {
  top: 22px !important;
  min-height: 48px;
}
</style>
